import { useEffect } from 'react';

const useKeyboardEffect = (
  visibleCommands: string[],
  selectedIndex: number,
  onSelectCommand: (command: string) => void,
  setSelectedIndex: (value: React.SetStateAction<number>) => void,
  setVisibleCommands: React.Dispatch<React.SetStateAction<string[]>>
) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (!visibleCommands.length) return;
      switch (event.key) {
        case 'Enter':
          onSelectCommand(visibleCommands[selectedIndex]);
          break;
        case 'ArrowUp':
          setSelectedIndex((prev) =>
            prev > 0 ? prev - 1 : visibleCommands.length - 1
          );
          break;
        case 'ArrowDown':
          setSelectedIndex((prev) =>
            prev < visibleCommands.length - 1 ? prev + 1 : 0
          );
          break;
        case 'Escape':
          setVisibleCommands([]);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    visibleCommands,
    selectedIndex,
    onSelectCommand,
    setSelectedIndex,
    setVisibleCommands,
  ]);
};

export default useKeyboardEffect;
