import { ActionTypes } from '@botfabrik/engine-domain';
import queryString from 'query-string';
import { Middleware, MiddlewareAPI } from 'redux';
import io, { Socket } from 'socket.io-client';
import { AppDispatch, RootState } from '.';
import { Devices } from '../../../src';
import * as ChatActions from '../actions';
import * as ClientActions from '../actions';
import * as ConnectionActions from '../actions/connectionActions';
import { mergeMessages } from '../locales';
import { updateIntl } from '../reducers/intlReducer';
import {
  disableStartScreen,
  enableStartScreen,
} from '../reducers/startScreenReducer';
import { EnableConversationRatingType } from '../types';

// Blacklisted actions are not forwarded to the server
const blacklist = [
  ActionTypes.MESSAGE_TO_GUEST,
  ActionTypes.EVENT_TO_GUEST,
  ActionTypes.TYPING_ON,
  ActionTypes.TYPING_OFF,
  ActionTypes.RESTART_CHAT_REQUEST,
  ...ConnectionActions.INTERNAL_ACTIONS,
  ...ChatActions.INTERNAL_ACTIONS,
  '@@localize/SET_ACTIVE_LANGUAGE',
  '@@localize/ADD_TRANSLATION_FOR_LANGUAGE',
];

const socketMiddleware: Middleware =
  ({ dispatch, getState }: MiddlewareAPI<AppDispatch, RootState>) =>
  (next) => {
    let socket: Socket | null = null;

    return (action: any) => {
      const result = next(action);

      if (action.type === ConnectionActions.CONNECT) {
        if (socket != null) {
          socket.disconnect();
        }
        dispatch(ConnectionActions.connecting());

        const chatPath = `/chat?${queryString.stringify(action.querystrings)}`;

        const isLocal = import.meta.env.VITE_IS_LOCAL === 'true';
        socket = io(
          isLocal
            ? `ws://localhost:3000/chatbot${chatPath}`
            : `/${action.clientName}${chatPath}`
        );

        socket.on('action', dispatch);
        socket.on('connect', () => dispatch(ConnectionActions.connected()));
        socket.on('disconnect', () =>
          dispatch(ConnectionActions.disconnected())
        );
        socket.on('reconnect', () => dispatch(ConnectionActions.reconnected()));

        socket.on('set-use-start-screen', (useStartScreen: boolean) => {
          dispatch(useStartScreen ? enableStartScreen() : disableStartScreen());
        });

        socket.on('set-commands', (commands: string[] = []) => {
          dispatch(ChatActions.setCommands(commands));
        });

        socket.on(
          'set-translations',
          (language: string, translation: Record<string, string>) => {
            dispatch(
              updateIntl({
                language,
                messages: mergeMessages(language, translation),
              })
            );
          }
        );
        socket.on('enable-speech-support', () =>
          dispatch(ChatActions.enableSpeechSupport())
        );
        socket.on(
          'enable-transcript-export',
          (props: { pdf: boolean; email: boolean }) =>
            dispatch(ChatActions.enableTranscriptExport(props))
        );
        socket.on(
          'enable-conversation-rating',
          (rating: EnableConversationRatingType) =>
            dispatch(ChatActions.enableConversationRating(rating))
        );
        socket.on('speech-transcription', (transcription: string) =>
          dispatch(ChatActions.receiveSpeechTranscription(transcription))
        );
        socket.on('restore-client-state', (state: any) => {
          dispatch(ConnectionActions.restoreSessionId(state.sessionId));
          dispatch(ChatActions.restorePreviousChatMessages(state.messages));
        });
        socket.on('expand-window', ({ devices }: { devices: Devices }) =>
          dispatch({ type: ClientActions.EXPAND_WINDOW, devices })
        );
        socket.on('show-general-conditions', () =>
          dispatch(ChatActions.showGeneralConditions())
        );
        socket.on('restart-chat', () => {
          dispatch(
            ConnectionActions.restartChat(
              action.clientName,
              action.querystrings
            )
          );
        });
      } else if (action.type === ConnectionActions.START) {
        socket?.emit('start-chat', action.payload);
      } else if (action.type === ConnectionActions.TERMINATE_SESSION) {
        socket?.emit('terminate-session', action.payload);
      } else if (action.type === ChatActions.GET_STARTED) {
        socket?.emit('get-started', JSON.stringify(action));
      } else if (action.type === ChatActions.AUDIO_MESSAGE_FROM_USER) {
        socket?.emit('audio-message', action.buffer);
      } else if (action.type === ActionTypes.CONVERSATION_RATING_FROM_GUEST) {
        socket?.emit('conversation-rating', action.payload);
      } else if (action.type === ChatActions.DOWNLOAD_TRANSCRIPT_PDF) {
        const {
          connection: { sessionId },
        } = getState();
        window.location.href = `/transcript-pdf/${sessionId}`;
      } else if (
        blacklist.indexOf(action.type) === -1 &&
        action.meta === undefined
      ) {
        // Foreward all other actions to the server
        if (socket) {
          socket.emit('action', JSON.stringify(action));
        } else {
          // TODO: wenn socket undefined ist, diese sammeln und später abarbeiten
        }
      }

      return result;
    };
  };

export default socketMiddleware;
