import classNames from 'classnames';
import React, { KeyboardEventHandler, useEffect, useRef } from 'react';
import useTranslation from '../../../../locales/useTranslation';
import styles from './TextInput.module.scss';
import AudioRecorder from './audio-recorder';

interface Props {
  speechSupport: boolean;
  transcriptExportEnabled: boolean;
  disabled: boolean;
  textInput: string;
  commandsVisible: boolean;
  onTextInput: (input: string) => void;
  onTextInputSubmit: (input: string) => boolean;
  onAudioRecorded: (blob: Blob) => void;
  onOpenTranscriptExportModal: () => void;
}

const TextInput = ({
  speechSupport,
  transcriptExportEnabled,
  disabled,
  textInput,
  commandsVisible,
  onTextInput,
  onTextInputSubmit,
  onAudioRecorded,
  onOpenTranscriptExportModal,
}: Props) => {
  const translate = useTranslation();
  const userTextInput = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    userTextInput.current?.focus();
  }, [textInput]);

  const submit = () => {
    const currentUserInput = userTextInput.current;
    if (currentUserInput && textInput.trim().length >= 1) {
      const accepted = onTextInputSubmit(textInput.trim());
      if (accepted) {
        currentUserInput.value = '';
        currentUserInput.style.height = '';
        currentUserInput.focus();
      }
    }
  };

  const handleOnFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  };

  const handleOnInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    onTextInput(value);

    event.target.style.height = '';
    const { scrollHeight } = event.target;
    event.target.style.height = `${scrollHeight > 150 ? 150 : scrollHeight}px`;
  };

  const handleOnKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (event.code === 'Enter' && !event.shiftKey) {
      if (!event.repeat) {
        submit();
      }
      event.preventDefault(); // Prevents the addition of a new line in the text field
    } else if (
      commandsVisible &&
      (event.code === 'ArrowUp' || event.code === 'ArrowDown')
    ) {
      // prevent shift cursor to the beginning or end of the text if command list is visible
      event.preventDefault();
    }
  };

  return (
    <form
      className={classNames(styles.inputWrapper, 'chat__textInput')}
      onSubmit={handleOnFormSubmit}
    >
      <div className={styles.messageInputWrapper}>
        <textarea
          id="user_input"
          ref={userTextInput}
          className={styles.messageInput}
          tabIndex={0}
          placeholder={translate('message-input-placeholder')}
          value={textInput}
          onChange={handleOnInputChange}
          onKeyDown={handleOnKeyDown}
          aria-label={translate('message-input-label')}
          aria-controls="command-list"
          aria-haspopup="listbox"
        />
      </div>
      <div className={classNames(styles.buttons, 'chat__buttons')}>
        {!textInput.length && transcriptExportEnabled && (
          <button
            type="button"
            className={classNames(styles.button, 'chat__button')}
            aria-label={translate(
              'message-input.actions.export-transcript.label'
            )}
            data-title={translate(
              'message-input.actions.export-transcript.label'
            )}
            tabIndex={0}
            onClick={onOpenTranscriptExportModal}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M456 231a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0zm0 280a56 56 0 10112 0 56 56 0 10-112 0z"></path>
            </svg>
          </button>
        )}
        {!!textInput.length ||
        !speechSupport ||
        navigator.mediaDevices === undefined ? (
          <button
            className={classNames(styles.button, 'chat__button')}
            type="submit"
            aria-label={translate('message-input.actions.submit.label')}
            data-title={translate('message-input.actions.submit.label')}
            tabIndex={0}
            disabled={disabled}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path>
            </svg>
          </button>
        ) : (
          speechSupport &&
          navigator.mediaDevices !== undefined && (
            <AudioRecorder
              translate={translate}
              onAudioRecorded={onAudioRecorded}
            />
          )
        )}
      </div>
    </form>
  );
};

export default TextInput;
